import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Container as ContainerComponent } from '@material-ui/core';

import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
  },
}));

function Container({ className, maxWidth, children }) {
  const classes = useStyles();
  return (
    <ContainerComponent
      disableGutters
      className={clsx(classes.root, className)}
      maxWidth={maxWidth}
    >
      {children}
    </ContainerComponent>
  );
}

Container.defaultProps = {
  maxWidth: 'xl',
};

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
};

export { Container };
