import React from 'react';

import PropTypes from 'prop-types';


import { SelectFilter } from '../SelectFilter';

const FacetFilter = props => {
  const {
    placeholder,
    facet,
    isLoading,
    error,
    values,
    onChange,
    labelFormatter,
    sortFunction,
    ...rest
  } = props;

  let options = [];
  if (facet) {
    options = facet.values
      .map(({ value, count }) => ({
        label: `${labelFormatter(value)} (${count})`,
        count,
        value,
      }))
      .sort(sortFunction);
  }

  return (
    <SelectFilter
      isDisabled={Boolean(error)}
      isLoading={isLoading}
      placeholder={"Aucun filtre..."}
      options={options}
      value={values.map(value => ({
        key: value,
        label: labelFormatter(value),
        value,
      }))}
      label={placeholder}
      onChange={onChange}
      {...rest}
    />
  );
};

FacetFilter.propTypes = {
  placeholder: PropTypes.string,
  facet: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  labelFormatter: PropTypes.func,
  sortFunction: PropTypes.func,
};

FacetFilter.defaultProps = {
  labelFormatter: v => v,
  sortFunction: (a, b) => {
    if (a.count < b.count) {
      return 1;
    } else if (a.count > b.count) {
      return -1;
    }
    return 0;
  },
};

export { FacetFilter };
