import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Drawer,
  LinearProgress,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListSubheader,
} from '@material-ui/core';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import BarChartIcon from '@material-ui/icons/BarChart';
import TimelineIcon from '@material-ui/icons/Timeline';

import map from 'lodash/map';
import { useLoadingRoute, useCurrentRoute, NotFoundBoundary } from 'react-navi';
import { useKeycloak } from 'react-keycloak';

import { useGoto } from '#/hooks';

import { Item } from './Item';

// "subheader"<array>: [
//   {
//     label<string>
//     link<string>
//     Icon<element>
//     actions<array>: [
//       {
//         label<string>
//         link<string>
//         Icon<element>
//       }
//     ]
//   }
// ]
const list = {
  'Tableaux de bord': [
    {
      label: 'Utilisation par outil',
      link: '/usageByTool',
      Icon: BarChartIcon,
    },
    {
      label: `Timeline d'utilisation`,
      link: '/usageTimeline',
      Icon: TimelineIcon,
    },
  ],
};

const width = 300;

const useStyles = makeStyles(theme => ({
  toolbar: {
    minHeight: theme.mycad.appBar,
    padding: '0px 12px',
  },
  flexGrow: {
    flexGrow: 1,
  },
  burgerIcon: {
    marginRight: theme.spacing(1),
  },
  mainTitle: {
    cursor: 'pointer',
    color: '#8f8f8f',
    paddingLeft: theme.spacing(1),
  },
  drawer: {
    flexShrink: 0,
    zIndex: `${theme.zIndex.appBar - 1} !important`,
  },
  drawerContainer: {
    width,
    marginTop: theme.mycad.appBar,
  },
  container: {
    paddingTop: theme.mycad.appBar,
    height: '100%',
  },
  pageTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderLeft: '1px solid #e6e6e6',
  },
  pageIcon: {
    marginRight: theme.spacing(1),
  },
  pageTitle: {
    color: '#4d4d4d',
  },
}));

const Layout = ({ children, renderNotFound }) => {
  const loadingRoute = useLoadingRoute();
  const [keycloak] = useKeycloak();
  const classes = useStyles();
  const goTo = useGoto();
  const route = useCurrentRoute();

  const [open, setOpen] = useState(false);

  function handleOpenDrawer() {
    setOpen(!open);
  }

  function handleCloseDrawer() {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <AppBar position="fixed" color="inherit">
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            className={classes.burgerIcon}
            onClick={handleOpenDrawer}
          >
            <MenuIcon />
          </IconButton>
          <img src="/logo.png" height="32" width="32" />
          <Typography
            variant="h6"
            className={classes.mainTitle}
            onClick={goTo('/')}
          >
            myCAD
          </Typography>

          {!loadingRoute && (
            <div className={classes.pageTitleContainer}>
              {<route.data.Icon color="action" className={classes.pageIcon} />}
              <Typography variant="h6" className={classes.pageTitle}>
                {route.title}
              </Typography>
            </div>
          )}
          <div className={classes.flexGrow} />
          <IconButton color="inherit" onClick={keycloak.logout}>
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        open={open}
        onClose={handleCloseDrawer}
      >
        <div className={classes.drawerContainer}>
          {map(list, (items, key) => (
            <List
              key={key}
              subheader={
                <ListSubheader component="div" disableSticky>
                  {key}
                </ListSubheader>
              }
            >
              {items.map(item => (
                <Item key={item.label} onClose={handleCloseDrawer} {...item} />
              ))}
            </List>
          ))}
        </div>
      </Drawer>
      {loadingRoute && <LinearProgress color="secondary" />}
      <NotFoundBoundary render={renderNotFound}>
        <div className={classes.container}>{children}</div>
      </NotFoundBoundary>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
  renderNotFound: PropTypes.func.isRequired,
};

export { Layout };
