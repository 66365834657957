import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Table as TableComponent,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from '@material-ui/core';

import { Toolbar } from './Toolbar';
import { Head } from './Head';
import { Row } from './Row';
import { stableSort, getSorting } from './helpers';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  addCustomer: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(2),
  },
}));

function Table(props) {
  const {
    columns,
    data,
    isSearchable,
    searchFieldDefaultValue,
    rightComponent,
  } = props;

  const classes = useStyles();

  // For searchField
  const [value, setValue] = useState(searchFieldDefaultValue);
  const [filters, setFilters] = useState([]);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const rows = data;

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(ev, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(ev) {
    const { value } = ev.target;
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  let rowsSearched = [];
  if (!filters || !filters.length) {
    rowsSearched = rows.filter(row => {
      if (!filters || !filters.length) {
        const arrayOfValue = Object.values(row).map(value =>
          typeof value === 'string' ? value : ''
        );
        return (
          arrayOfValue
            .join(' ')
            .toLowerCase()
            .trim()
            .indexOf(value.toLowerCase().trim()) !== -1
        );
      }
    });
  } else {
    const groupedFilters = filters.reduce(
      (acc, filter) => {
        if (!acc.groups[filter.key]) {
          acc.groups[filter.key] = [filter.value.trim()];
          acc.filters.push({ key: filter.key, values: acc.groups[filter.key] });
        } else {
          acc.groups[filter.key].push(filter.value.trim());
        }
        return acc;
      },
      { filters: [], groups: {} }
    );
    rowsSearched = rows.filter(row =>
      groupedFilters.filters
        .reduce((takeItArr, filter) => {
          takeItArr.push(
            filter.values.includes(row[filter.key].toLowerCase().trim())
          );
          return takeItArr;
        }, [])
        .includes(true)
    );
  }

  function handleSearchFieldChange(ev, value, filters) {
    setFilters(filters || []);
    setValue(value || '');
  }

  return (
    <React.Fragment>
      <div className={classes.tableWrapper}>
        {isSearchable && (
          <Toolbar
            columns={columns}
            value={value}
            onChange={handleSearchFieldChange}
            rightComponent={rightComponent}
          />
        )}
        <TableComponent
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <Head
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={columns}
          />
          <TableBody>
            {stableSort(rowsSearched, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <Row key={JSON.stringify(row)} row={row} columns={columns} />
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </TableComponent>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Lignes par page"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} / ${count}`
        }
      />
    </React.Fragment>
  );
}

Table.defaultProps = {
  searchFieldDefaultValue: '',
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isSearchable: PropTypes.bool,
  searchFieldDefaultValue: PropTypes.string,
  rightComponent: PropTypes.element,
};

export { Table };
