import React from 'react';

import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core/styles';
import { IconButton, Tooltip, Zoom } from '@material-ui/core';

import { useGoto } from '#/hooks';

function Action(props) {
  const { label, link, Icon, onClose } = props;
  const goTo = useGoto();

  const theme = useTheme();

  function handleClickLink(path) {
    onClose();
    goTo(path)();
  }

  return (
    <Zoom in timeout={theme.transitions.duration.short}>
      <Tooltip title={label} placement="bottom">
        <IconButton edge="end" onClick={() => handleClickLink(link)}>
          <Icon />
        </IconButton>
      </Tooltip>
    </Zoom>
  );
}

Action.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  Icon: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { Action };
