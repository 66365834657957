import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { blueGrey } from '@material-ui/core/colors';

import NumberFormat from 'react-number-format';

const useStyles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing(3),
  },
  title: {
    fontWeight: 700,
    color: blueGrey[600],
  },
}));

const Counter = props => {
  const { title, value, isLoading, error } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography
        gutterBottom
        variant="body2"
        color="textSecondary"
        className={classes.title}
      >
        {title}
      </Typography>
      {(function() {
        if (isLoading) {
          return <Skeleton variant="text" width={80} height={32} />;
        }

        if (error) {
          return (
            <Typography variant="h5" color="error">
              Error...
            </Typography>
          );
        }

        return (
          <Typography variant="h5" color="textSecondary">
            <NumberFormat
              thousandSeparator=" "
              value={value}
              displayType="text"
            />
          </Typography>
        );
      })()}
    </div>
  );
};

Counter.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  error: PropTypes.any,
};

export { Counter };
