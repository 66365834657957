import React from 'react';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { KeycloakProvider } from 'react-keycloak';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import { SnackbarProvider } from 'notistack';

import { FullscreenLoader } from '#/components';
import { keycloak } from './auth';
import { AppRouter } from './router';
import { UrqlProvider } from './urql';

import 'moment/locale/fr';

const theme = createMuiTheme({
  mycad: {
    appBar: 52,
  },
  palette: {
    background: {
      default: '#edecec',
    },
    timeline: '#8884d8',
    usageByTool: '#4CAF50',
    panel: {
      blue: 'rgb(3, 169, 244)',
      purple: 'rgb(156, 39, 176)',
      green: 'rgb(76, 175, 80)',
      orange: 'rgb(255, 152, 0)',
      pink: 'rgb(203, 30, 99)',
      grey: 'rgb(99, 99, 99)',
    },
  },
});

export default function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <KeycloakProvider
        initConfig={{ onLoad: 'login-required' }}
        keycloak={keycloak}
        LoadingComponent={<FullscreenLoader />}
      >
        <React.Fragment>
          <CssBaseline />
          <UrqlProvider>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              locale="fr"
            >
              <SnackbarProvider maxSnack={5}>
                <AppRouter />
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </UrqlProvider>
        </React.Fragment>
      </KeycloakProvider>
    </MuiThemeProvider>
  );
}
