import { GRAPHQL_API_URL } from '#/globals';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useKeycloak } from 'react-keycloak';
import { createClient, Provider } from 'urql';

const UrqlProvider = ({ children }) => {
  const [keycloak, initialized] = useKeycloak();
  const [urqlClient, setUrqlClient] = useState({});

  useEffect(() => {
    const instance = createClient({
      url: GRAPHQL_API_URL,
      fetchOptions: () => ({
        headers: {
          authorization: initialized ? `Bearer ${keycloak.token}` : undefined,
        },
      }),
    });

    setUrqlClient({ instance });

    return () => {
      setUrqlClient({});
    };
  }, [initialized, keycloak]);

  return <Provider value={urqlClient.instance}>{children}</Provider>;
};

UrqlProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { UrqlProvider };
