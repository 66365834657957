import { useState } from 'react';

import trim from 'lodash/trim';

function fromQueryString(qs, removeQSInCaseOfErr) {
  try {

    const strSplittedAND = qs.replace(/\(*\)*/g,'').split('AND');
    const res = {};

    for (let i = 0; i < strSplittedAND.length; i++) {
      const currAND = strSplittedAND[i].trim();
      if (currAND.includes('ts')) {
        const cleanTs = trim(trim(currAND, 'ts:['), ']');
        const [from, to] = cleanTs.split('TO');
        res.ts = [from.trim(), to.trim()];
      } else if (!currAND.includes('OR')) {
        const [filter, value] = currAND.split(':');
        if (!res[filter]) {
          res[filter] = [];
        }
        res[filter].push(trim(value, '"'));
      } else {
        const strSplittedOR = currAND.split('OR');
        for (let j = 0; j < strSplittedOR.length; j++) {
          const currOR = strSplittedOR[j].trim();
          const [filter, value] = currOR.split(':');
          if (!res[filter]) {
            res[filter] = [];
          }
          res[filter].push(trim(value, '"'));
        }
      }
    }
    return res;
  } catch (err) {
    console.log(err);
    removeQSInCaseOfErr && removeQSInCaseOfErr()
    return {};
  }
}

const useQueryFilters = (defaultQueryString, removeQSInCaseOfErr) => {
  let defaultFilters = {};
  if (defaultQueryString && defaultQueryString !== '*:*') {
    defaultFilters = fromQueryString(defaultQueryString, removeQSInCaseOfErr);
  }

  const [monthFilters, setMonthFilters] = useState([]);
  const [toolNameFilters, setToolNameFilters] = useState(
    defaultFilters.tool_name || []
  );
  const [productVersionFilters, setProductVersionFilters] = useState(
    defaultFilters.product_version || []
  );
  const [varNameFilters, setVarNameFilters] = useState(
    defaultFilters.var_name || []
  );
  const [productNameFilters, setProductNameFilters] = useState(
    defaultFilters.product_name || []
  );
  const [companyFilters, setCompanyFilters] = useState(
    defaultFilters.company || []
  );
  const [swVersionFilters, setSwVersionFilters] = useState(
    defaultFilters.sw_version || []
  );
  const [licencetypeFilters, setLicencetypeFilters] = useState(
    defaultFilters.licencetype || []
  );
  const [fromToFilter, setFromToFilter] = useState(defaultFilters.ts || []);

  const toQueryString = () => {
    const queryFilters = [];
    const filtersToQuery = (filters, filterName) =>
      filters.map(f => `${filterName}:"${f}"`).join(' OR ');
    const addFilters = (filters, filterName) =>
      filters.length && queryFilters.push(filtersToQuery(filters, filterName));
    const filtersToAdd = [
      [monthFilters, 'month'],
      [toolNameFilters, 'tool_name'],
      [productVersionFilters, 'product_version'],
      [varNameFilters, 'var_name'],
      [productNameFilters, 'product_name'],
      [companyFilters, 'company'],
      [licencetypeFilters, 'licencetype'],
      [swVersionFilters, 'sw_version'],
    ];
    filtersToAdd.forEach(f => addFilters(f[0], f[1]));

    if (fromToFilter.length) {
      queryFilters.push(`ts:[${fromToFilter[0]} TO ${fromToFilter[1]}]`);
    }
    const queryString = queryFilters.length
      ? queryFilters.map(f => `( ${f} )`).join(' AND ')
      : '*:*';
    return queryString;
  };

  const toPartialQueryString = filterId => {
    const queryFilters = [];
    const filtersToQuery = (filters, filterName) =>
      filters.map(f => `${filterName}:"${f}"`).join(' OR ');
    const addFilters = (filters, filterName) =>
      filters.length && queryFilters.push(filtersToQuery(filters, filterName));
    const filtersToAdd = [
      [monthFilters, 'month'],
      [toolNameFilters, 'tool_name'],
      [productVersionFilters, 'product_version'],
      [varNameFilters, 'var_name'],
      [productNameFilters, 'product_name'],
      [companyFilters, 'company'],
      [licencetypeFilters, 'licencetype'],
      [swVersionFilters, 'sw_version'],
    ];
    filtersToAdd
      .filter(f => f[1] !== filterId)
      .forEach(f => addFilters(f[0], f[1]));
    if (fromToFilter.length) {
      queryFilters.push(`ts:[${fromToFilter[0]} TO ${fromToFilter[1]}]`);
    }
    const queryString = queryFilters.length
      ? queryFilters.map(f => `( ${f} )`).join(' AND ')
      : '*:*';
    return queryString;
  };

  return {
    toQueryString,
    fromQueryString,
    monthFilters,
    setMonthFilters,
    toolNameFilters,
    setToolNameFilters,
    productVersionFilters,
    setProductVersionFilters,
    varNameFilters,
    setVarNameFilters,
    productNameFilters,
    setProductNameFilters,
    companyFilters,
    setCompanyFilters,
    licencetypeFilters,
    setLicencetypeFilters,
    fromToFilter,
    setFromToFilter,
    swVersionFilters,
    setSwVersionFilters,
    toPartialQueryString,
  };
};

export { useQueryFilters, fromQueryString };
