import { useQuery } from 'urql';

const query = `
  query useTimeFacets($query:String!, $fields: [String]!, $limit:Int) {
    facets(query:$query, fields: $fields, limit: $limit) {
      key
      values {
        value
        count
      }
    }
  }
`;

const useTimeFacets = (searchQuery, step) =>
  useQuery({
    query,
    variables: {
      query: searchQuery,
      fields: [step],
      limit: 100000,
    },
  });

export { useTimeFacets };
