import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

// import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { FormControlLabel } from '@material-ui/core';

// const animatedComponents = makeAnimated();

const customStyles = {
  control: (base, state) => ({
    ...base,
    borderRadius: `0px 4px 4px 0px`,
  }),
  placeholder: (base, state) => ({
    ...base,
    fontSize: '1rem',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    if (
      data.count &&
      !isDisabled &&
      !isSelected &&
      !isFocused &&
      data.count === '0'
    ) {
      return {
        ...styles,
        backgroundColor: '#CCCCCC',
      };
    } else {
      return styles;
    }
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    // padding: `0px ${theme.spacing(1)}px`,
    display: 'flex',
    minWidth: '100%',
  },
  control: {
    width: '100%',
    
  },
  label: {
    alignItems: 'start',
    marginLeft: 0
  },
  prefix: {
    borderRadius: `4px 0px 0px 4px`,
    backgroundColor: 'hsl(0,0%,80%)',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  select: {
    width: '100%',
  },
  icon: {
    color: 'white',
  },
}));

function SelectFilter(props) {
  const { Icon, ...rest } = props;
  const classes = useStyles();

  const [ctrlPressed, setCtrlPressed] = useState(false);

  function handleKeyDown(ev) {
    if (ev.keyCode === 17 || ev.charCode === 17) {
      setCtrlPressed(true);
    }
  }

  function handleKeyUp(ev) {
    if (ev.keyCode === 17 || ev.charCode === 17) {
      setCtrlPressed(false);
    }
  }

  return (
    <FormControlLabel
      labelPlacement="top"
      label={props.label}
      classes={{ root: classes.control, labelPlacementTop: classes.label}}
      control={<div
        className={classes.root}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
      >
        {Icon && (
          <div className={classes.prefix}>
            <Icon fontSize="small" className={classes.icon} />
          </div>
        )}
        <Select
          isMulti
          id={"demo-simple-select-label"}
          isClearable
          closeMenuOnSelect={!ctrlPressed}
          // components={animatedComponents}
          styles={customStyles}
          className={classes.select}
          {...rest}
        />
      </div>}/>
    
  );
}

SelectFilter.propTypes = {
  Icon: PropTypes.object,
  payload: PropTypes.string,
  label: PropTypes.string,
};

export { SelectFilter };
