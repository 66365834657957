const useKeepQueryString = prefix => {
  const id = `[${prefix}]:queryString`;

  function setQs(qs) {
    window.localStorage.setItem(id, qs);
  }

  function getQs() {
    const qs = window.localStorage.getItem(id);
    return qs === 'null' ? null : qs;
  }

  function removeQs() {
    window.localStorage.removeItem(id);
  }

  return { setQs, getQs, removeQs };
};

export { useKeepQueryString };
