import { CSV_API_URL } from '#/globals';
import { useKeycloak } from 'react-keycloak';
import fetch from 'isomorphic-fetch';

async function* makeTextFileLineIterator(fileURL, cancelStatus) {
  const utf8Decoder = new TextDecoder('utf-8');
  const response = await fetch(fileURL);
  const reader = response.body.getReader();
  let { value: chunk, done: readerDone } = await reader.read();
  chunk = chunk ? utf8Decoder.decode(chunk) : '';
  const re = /\n|\r|\r\n/gm;
  let startIndex = 0;

  for (;;) {
    const result = re.exec(chunk);
    if (cancelStatus.cancelled) {
      setTimeout(() => {
        reader.cancel();
      }, 100);
      break;
    }
    if (!result) {
      if (readerDone) {
        break;
      }
      const remainder = chunk.substr(startIndex);
      ({ value: chunk, done: readerDone } = await reader.read());
      chunk = remainder + (chunk ? utf8Decoder.decode(chunk) : '');
      startIndex = re.lastIndex = 0;
      continue;
    }
    yield chunk.substring(startIndex, result.index);
    startIndex = re.lastIndex;
  }
  if (startIndex < chunk.length) {
    // last line didn't end in a newline char
    yield chunk.substr(startIndex);
  }
}

const useDownloadCSV = () => {
  const [keycloak] = useKeycloak();
  return async (urlQuery, progressFunc, cancelStatus) => {
    let interval;
    try {
      const queryString = new URLSearchParams({
        ...urlQuery,
        access_token: keycloak.token,
      });
      const cache = [];
      const startTime = Date.now();
      interval = setInterval(() => {
        progressFunc(cache.length, startTime);
      }, 800);
      let line;
      for await (line of makeTextFileLineIterator(
        `${CSV_API_URL}?${queryString.toString()}`,
        cancelStatus
      )) {
        cache.push(line);
      }
      if (!cancelStatus.cancelled) {
        const url = window.URL.createObjectURL(
          new window.Blob([cache.join('\n')], { type: 'text/csv' })
        );
        const a = document.createElement('a');
        a.href = url;
        a.download = 'mycad-export.csv';
        a.click();
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      clearInterval(interval);
    }
  };
};

export { useDownloadCSV };
