import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Toolbar as ToolbarComponent } from '@material-ui/core';

import clsx from 'clsx';

import { SearchField } from './SearchField';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    flex: '1 1 100%',
  },
  rightComponent: {
    textAlign: 'right',
  },
}));

const Toolbar = props => {
  const { value, onChange, columns, rightComponent } = props;
  const classes = useStyles();

  const columnsFilter = columns
    .filter(c => c.searchable)
    .map(column => ({
      key: column.id,
      label: column.label.toLowerCase(),
      icon: 'view_column',
    }));

  let placeholder = 'Chercher...';
  if (columnsFilter && columnsFilter.length) {
    placeholder = `Exemple: ${columnsFilter[0].key}:<valeur>`;
  }

  return (
    <ToolbarComponent className={clsx(classes.root)}>
      <SearchField
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        columnsFilter={columnsFilter}
      />
      <div className={classes.rightComponent}>{rightComponent}</div>
    </ToolbarComponent>
  );
};

Toolbar.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  columns: PropTypes.array.isRequired,
  rightComponent: PropTypes.element,
};

export { Toolbar };
