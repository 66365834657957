import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Select, MenuItem, InputAdornment } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import FunctionsIcon from '@material-ui/icons/Functions';

const useStyles = makeStyles(theme => ({
  container: {
    '& .MuiInput-underline:before': {
      borderColor: 'white !important',
    },
    '& .MuiInput-underline:after': {
      borderColor: 'white !important',
    },
  },
  white: {
    color: 'white',
  },
}));

function ScaleSelect(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Select
        key="scaleSelect"
        value={props.value}
        onChange={props.onChange}
        className={classes.white}
        classes={{
          icon: classes.white,
        }}
        startAdornment={
          <InputAdornment position="start">
            <FunctionsIcon className={classes.white} />
          </InputAdornment>
        }
      >
        <MenuItem value="linear">Linéaire</MenuItem>
        <MenuItem value="log">Logarithmique</MenuItem>
      </Select>
    </div>
  );
}

ScaleSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function useScale(defaultScale) {
  const [scale, setScale] = useState(defaultScale);
  const handleChangeScale = e => {
    const { value } = e.target;
    setScale(value);
  };
  return [
    scale,
    <ScaleSelect
      key="scaleSelect"
      value={scale}
      onChange={handleChangeScale}
    />,
  ];
}

export { useScale };
