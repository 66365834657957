import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Zoom } from '@material-ui/core';

import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

import { grey, amber, red, green } from '@material-ui/core/colors';

import { Container } from '#/components';

const variantIcon = {
  success: CheckCircleOutlinedIcon,
  warning: WarningOutlinedIcon,
  error: ErrorOutlinedIcon,
  info: InfoOutlinedIcon,
};

const useStyles = makeStyles(theme => ({
  box: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  primary: {
    padding: `${theme.spacing(1)}px 0px`,
  },
}));

function ResponsiveMessage(props) {
  const { primary, secondary, variant, color } = props;
  const classes = useStyles();
  const theme = useTheme();

  const Icon = variantIcon[variant];

  function getColor(variant) {
    switch (variant) {
      case 'info':
        return theme.palette.primary.main;
      case 'warning':
        return amber[700];
      case 'error':
        return red[500];
      case 'success':
        return green[500];
      default:
        return grey[700];
    }
  }

  return (
    <Container spacing={4}>
      <Zoom in timeout={theme.transitions.duration.short}>
        <div className={classes.box}>
          {Icon && (
            <Icon fontSize="large" htmlColor={color || getColor(variant)} />
          )}
          <Typography
            className={classes.primary}
            variant="h5"
            color="textSecondary"
          >
            {primary}
          </Typography>
          {secondary}
        </div>
      </Zoom>
    </Container>
  );
}

ResponsiveMessage.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  color: PropTypes.string,
};

export { ResponsiveMessage };
