import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';

import get from 'lodash/get';
import { useCurrentRoute } from 'react-navi';

import { useGoto } from '#/hooks';

import { Action } from './Action';

function Item(props) {
  const { actions, label, link, Icon, onClose } = props;
  const route = useCurrentRoute();
  const goTo = useGoto();

  const [isHover, setIsHover] = useState(false);

  function isSelected(path) {
    return get(route, 'url.pathname') === path ? 'primary' : 'inherit';
  }

  function handleClickLink(path) {
    onClose();
    goTo(path)();
  }

  function handleOnMouseOver() {
    setIsHover(true);
  }

  function handleOnMouseOut() {
    setIsHover(false);
  }

  return (
    <div onMouseEnter={handleOnMouseOver} onMouseLeave={handleOnMouseOut}>
      <ListItem button onClick={() => handleClickLink(link)}>
        <ListItemIcon>
          <Icon color={isSelected(link)} />
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            color: isSelected(link),
          }}
        />
        {actions && actions.length && isHover ? (
          <ListItemSecondaryAction>
            {actions.map(action => (
              <Action key={action.label} onClose={onClose} {...action} />
            ))}
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    </div>
  );
}

Item.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  Icon: PropTypes.object.isRequired,
  actions: PropTypes.array,
  onClose: PropTypes.func.isRequired,
};

export { Item };
