import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Zoom,
  Collapse,
  IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(1.5),
  },
  panelAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  panelHeading: {
    padding: theme.spacing(1),
    backgroundColor: ({ color }) => color,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: 'white',
  },
  panelBody: {
    padding: ({ padding }) => {
      return padding === 'normal' ? theme.spacing(1) : 0;
    },
  },
  expandIconButton: {
    marginLeft: theme.spacing(1),
  },
}));

const VisiaPaper = props => {
  const {
    title,
    color,
    children,
    elevation,
    icon,
    className,
    actions,
    padding,
    togglable,
    showChildren,
    unmountOnExit,
    mountOnEnter,
  } = props;
  const classes = useStyles({ color, padding });
  const theme = useTheme();
  const [showChildrenState, setShowChildrenState] = useState(showChildren);
  const paperAction = actions ? [...actions] : [];

  const handleToggleChildrenState = () => {
    setShowChildrenState(!showChildrenState);
  };
  if (togglable) {
    paperAction.push(
      <IconButton
        size="small"
        key="expand"
        className={classes.expandIconButton}
        onClick={handleToggleChildrenState}
      >
        {showChildrenState ? (
          <ExpandLessIcon className={classes.icon} />
        ) : (
          <ExpandMoreIcon className={classes.icon} />
        )}
      </IconButton>
    );
  }
  return (
    <Zoom in timeout={theme.transitions.duration.short}>
      <Paper elevation={elevation} className={clsx(classes.paper, className)}>
        <div className={classes.panelHeading}>
          <div className={classes.title}>
            {icon}&nbsp;
            <Typography variant={'subtitle1'}>{title}</Typography>
          </div>
          {paperAction && (
            <div className={classes.panelAction}>{paperAction}</div>
          )}
        </div>
        {!togglable ? (
          <div className={classes.panelBody}>{children}</div>
        ) : (
          <div className={classes.panelBody}>
            <Collapse
              mountOnEnter={mountOnEnter}
              unmountOnExit={unmountOnExit}
              in={showChildrenState}
            >
              {children}
            </Collapse>
          </div>
        )}
      </Paper>
    </Zoom>
  );
};

VisiaPaper.defaultProps = {
  elevation: 4,
  padding: 'normal',
  togglable: false,
  showChildren: false,
  mountOnEnter: true,
  unmountOnExit: true,
};

VisiaPaper.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  children: PropTypes.node,
  elevation: PropTypes.number,
  icon: PropTypes.element,
  className: PropTypes.string,
  actions: PropTypes.node,
  padding: PropTypes.oneOf(['none', 'normal']),
  togglable: PropTypes.bool.isRequired,
  showChildren: PropTypes.bool,
  mountOnEnter: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
};

export { VisiaPaper };
