import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  InputBase,
  InputAdornment,
  IconButton,
  Typography,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';

import clsx from 'clsx';
import trimStart from 'lodash/trimStart';

const REGEX_DETECT_FILTERS = /\s?([a-zA-Z0-9]+:[a-z0-9]+)\s?/gm;
const REGEX_REPLACE_FILTERS = /\s?([a-zA-Z0-9]+:)\s?/gm;

const width = 500;

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width,
    // transitionProperty: 'width',
    // transitionDuration: '0.3s',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  rootInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  input: {
    color: theme.palette.grey[600],
  },
  divider: {
    height: 28,
    margin: 4,
  },
  iconButton: {
    padding: 10,
  },
  popover: {
    width,
  },
  listItemText: {
    marginLeft: 56,
  },
}));

function SearchField(props) {
  const {
    isLoading,
    columnsFilter,
    inputProps,
    placeholder,
    className,
    value,
    onChange,
    ...rest
  } = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const inputEl = useRef(null);

  useEffect(() => {
    onChange &&
      onChange(
        null,
        value,
        detectColumnFilter(value),
        getValueWithoutFilters(value)
      );
  }, []);

  function handleOpenPopover(ev) {
    setAnchorEl(ev.currentTarget);
  }

  function handleClosePopover() {
    setAnchorEl(null);
  }

  function getValueWithoutFilters(value) {
    return trimStart(value.replace(REGEX_REPLACE_FILTERS, ' '));
  }

  function detectColumnFilter(value) {
    const matchs = value.match(REGEX_DETECT_FILTERS) || [];
    const filterFound = [];
    matchs.forEach(match => {
      const [filterName, filterValue] = match.split(':');
      const columnFilter = columnsFilter.find(
        col => col.key === filterName.trim()
      );
      if (columnFilter) {
        filterFound.push({
          key: columnFilter.key,
          label: filterName,
          value: filterValue,
        });
      }
    });
    return filterFound;
  }

  function handleResetInput() {
    onChange && onChange(null, '', '', []);
  }

  function handleChangeFilter(filter) {
    onChange &&
      onChange(
        null,
        `${value} ${filter.key}:`,
        detectColumnFilter(value),
        getValueWithoutFilters(value)
      );
    handleClosePopover();
    setTimeout(() => {
      inputEl.current.focus();
      inputEl.current.selectionStart = inputEl.current.value.length;
      inputEl.current.selectionEnd = inputEl.current.value.length;
    }, 100);
  }

  function handleChangeInput(ev) {
    const { value } = ev.target;
    onChange &&
      onChange(
        ev,
        value,
        detectColumnFilter(value),
        getValueWithoutFilters(value)
      );
  }

  const open = Boolean(anchorEl);
  const id = open ? 'events-search-field-popover' : undefined;

  return (
    <div id={'search-field-root-div'} className={clsx(classes.root, className)}>
      <IconButton disabled className={classes.iconButton} aria-label={'search'}>
        {isLoading ? <CircularProgress size={20} /> : <Icon>search</Icon>}
      </IconButton>
      <InputBase
        {...rest}
        {...inputProps}
        inputRef={inputEl}
        classes={{
          root: classes.rootInput,
          input: classes.input,
        }}
        placeholder={placeholder}
        onChange={handleChangeInput}
        value={value}
        endAdornment={
          <InputAdornment position={'end'}>
            <Tooltip title="Effacer" placement={'bottom'}>
              <IconButton
                className={classes.iconButton}
                onClick={handleResetInput}
              >
                <Icon>clear</Icon>
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
      {columnsFilter && columnsFilter.length ? (
        <React.Fragment>
          <Divider className={classes.divider} orientation={'vertical'} />
          <Tooltip title="Filtres" placement={'bottom'}>
            <IconButton
              className={classes.iconButton}
              aria-label={'directions'}
              onClick={handleOpenPopover}
            >
              <Icon>arrow_drop_down</Icon>
            </IconButton>
          </Tooltip>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            classes={{ paper: classes.popover }}
          >
            <List disablePadding dense>
              {columnsFilter.map(filter => (
                <div key={filter.key}>
                  <ListItem button onClick={() => handleChangeFilter(filter)}>
                    {filter.icon && (
                      <ListItemIcon>
                        <Icon style={{ color: filter.color }}>
                          {filter.icon}
                        </Icon>
                      </ListItemIcon>
                    )}
                    <ListItemText
                      primary={
                        <Typography color={'textSecondary'}>
                          {`Chercher sur la colonne ${filter.key}`}
                        </Typography>
                      }
                      className={clsx({
                        [classes.listItemText]: !filter.icon,
                      })}
                    />
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </List>
          </Popover>
        </React.Fragment>
      ) : null}
    </div>
  );
}

SearchField.propTypes = {
  isLoading: PropTypes.bool,
  /**
   * List of column to filter (object)
   * key: <String> Key will be displayed in input (mandatory)
   * label: <String> The text will be displayed in filters menu (mandatory)
   * icon: <String> The font icon will be displayed in filters menu
   * color: <String> Color of icon
   * @type {Array}
   */
  columnsFilter: PropTypes.array,
  inputProps: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  /**
   * The onChange function
   * ev: The element
   * value: <String> Value
   * valueClear: <String> Value without any filters
   * filters: <Array> Array of filters
   * @type {Function}
   */
  onChange: PropTypes.func,
};

export { SearchField };
