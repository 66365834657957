import React, { Suspense } from 'react';

import { mount, lazy, redirect } from 'navi';
import { Router, View } from 'react-navi';

import { FullscreenLoader } from '#/components';
import { Layout } from '#/layout';
import { NotFound } from './NotFound';

// https://frontarm.com/navi/en/

const routes = mount({
  // Dashboards
  '/usageByTool': lazy(() => import('#/pages/usageByTool')),
  '/usageTimeline': lazy(() => import('#/pages/usageTimeline')),
  '/': redirect('/usageByTool'),
});

const AppRouter = () => (
  <Router routes={routes}>
    <Layout renderNotFound={NotFound}>
      <Suspense fallback={<FullscreenLoader />}>
        <View />
      </Suspense>
    </Layout>
  </Router>
);

export { AppRouter };
