import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { Box, CircularProgress } from '@material-ui/core';

import { background } from '#/styles/trianglify.scss';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    background,
  },
  root: {
    height: '100%',
    padding: 0,
    margin: 0,
  },
  content: {
    width: '100%',
    textAlign: 'center',
  },
}));

const FullscreenLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Box
        className={classes.root}
        display={'flex'}
        alignItems={'center'}
        p={1}
        m={1}
      >
        <Box className={classes.content} p={1}>
          <CircularProgress size={60} />
        </Box>
      </Box>
    </div>
  );
};

export { FullscreenLoader };
