import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  Select,
  FormControl,
  InputLabel,
  Typography,
  MenuItem,
  LinearProgress,
  DialogContentText,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';

import NumberFormat from 'react-number-format';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import { useDownloadCSV } from '#/hooks';
import moment from 'moment-timezone';
import 'moment-duration-format';

function CSVExportDialog({
  open,
  onClose,
  delimiter,
  header,
  quoted,
  queryString,
  lineCount,
}) {
  const [delimiterValue, setDelimiter] = useState(delimiter);
  const [headerValue, setHeaderValue] = useState(header);
  const [quotedValue, setQuoted] = useState(quoted);
  const handleDelimiterChange = e => setDelimiter(e.target.value);
  const handleHeaderChange = e => setHeaderValue(e.target.checked);
  const handleQuotedChange = e => setQuoted(e.target.checked);
  const [csvLoading, setCsvLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [startTime, setStartTime] = useState(-1);
  const downloadCSV = useDownloadCSV();
  const [handleClose, setHandleClose] = useState(() => onClose);
  const [cancelling, setCancelling] = useState(false);
  const [errorOpened, setErrorOpened] = useState(false);

  const handleCloseErrorModal = () => {
    setErrorOpened(false);
  };

  const handleOkClick = () => {
    setCsvLoading(true);
    const cancelStatus = { cancelled: false };
    setHandleClose(() => () => {
      cancelStatus.cancelled = true;
      setCancelling(true);
    });
    downloadCSV(
      {
        delimiter: delimiterValue,
        header: headerValue,
        quoted: quotedValue,
        q: queryString,
      },
      (progress, startTime) => {
        setProgress(progress);
        setStartTime(startTime);
      },
      cancelStatus
    )
      .then(() => {
        setCsvLoading(false);
        setHandleClose(() => onClose);
        setCancelling(false);
        onClose();
      })
      .catch(err => {
        setCsvLoading(false);
        setCancelling(false);
        setHandleClose(() => onClose);
        setErrorOpened(true);
      });
  };
  const remainingTime = (() => {
    if (startTime > 0 && progress > 0) {
      const now = Date.now();
      return moment
        .duration(((lineCount - progress) * (now - startTime)) / progress)
        .format('h [h] m [m] s [s]');
    } else {
      return null;
    }
  })();
  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogTitle id="form-dialog-export-csv">
          Exporter CSV
          {!csvLoading ? (
            <DialogContentText>
              <NumberFormat
                thousandSeparator=" "
                value={lineCount}
                displayType="text"
              />
              {` lignes à télécharger`}
            </DialogContentText>
          ) : cancelling ? (
            <React.Fragment>
              <DialogContentText>
                Annulation du téléchargement...
              </DialogContentText>
              <LinearProgress variant="indeterminate" />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <DialogContentText>
                Téléchargement en cours{' '}
                <NumberFormat
                  thousandSeparator=" "
                  value={progress}
                  displayType="text"
                />
                {' / '}
                <NumberFormat
                  thousandSeparator=" "
                  value={lineCount}
                  displayType="text"
                />
                {' ('}
                <NumberFormat
                  value={Math.floor((progress / lineCount) * 100)}
                  displayType="text"
                />
                {'%) '}
                {remainingTime}
              </DialogContentText>
              <LinearProgress
                value={(parseInt(progress) / parseInt(lineCount)) * 100}
                variant="determinate"
              />
            </React.Fragment>
          )}
        </DialogTitle>

        <DialogContent>
          <FormGroup>
            <FormControl disabled={csvLoading}>
              <InputLabel id="delimiter-id">Délimiteur</InputLabel>
              <Select
                autoFocus
                value={delimiterValue}
                onChange={handleDelimiterChange}
                id="delimiter-id"
                cy-data="delimiter-input"
                label=""
                fullWidth
              >
                <MenuItem value=";">Point-virgule (;)</MenuItem>
                <MenuItem value=",">Virgule (,)</MenuItem>
                <MenuItem value="\t">Tabulation</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              disabled={csvLoading}
              control={
                <Switch
                  checked={headerValue}
                  onChange={handleHeaderChange}
                  name="header"
                />
              }
              label="En-tête"
            />
            <FormControlLabel
              disabled={csvLoading}
              control={
                <Switch
                  checked={quotedValue}
                  onChange={handleQuotedChange}
                  name="quoted"
                />
              }
              label="Ajouter des guillemets"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            cy-data="cancel-button"
            disabled={cancelling}
          >
            Annuler
          </Button>
          <Button
            disabled={csvLoading}
            onClick={handleOkClick}
            cy-data="ok-button"
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={errorOpened} onClose={handleCloseErrorModal}>
        <DialogContent>
          <ErrorOutlinedIcon fontSize="large" htmlColor={red[500]} />
          <Typography variant="h5" color="textSecondary">
            {`Une erreur est survenue lors de l'export CSV`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseErrorModal}
            color="secondary"
            cy-data="cancel-button"
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

CSVExportDialog.defaultProps = {
  delimiter: ';',
  header: true,
  quoted: false,
};

CSVExportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  delimiter: PropTypes.string.isRequired,
  header: PropTypes.bool.isRequired,
  quoted: PropTypes.bool.isRequired,
  queryString: PropTypes.string.isRequired,
  lineCount: PropTypes.string.isRequired,
};

export { CSVExportDialog };
