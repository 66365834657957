import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, ListItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cell: {
    // padding: `0px ${theme.spacing(2)}px`,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

function Row(props) {
  const { row, columns } = props;
  const classes = useStyles();
  return (
    <TableRow tabIndex={-1}>
      {columns.map((column, index) => {
        const value = row[column.id];
        const onClick = column.onCellClick
          ? () => column.onCellClick(value)
          : undefined;
        return (
          <TableCell className={classes.cell} key={index}>
            <ListItem
              dense
              disableGutters
              button={Boolean(onClick)}
              onClick={onClick}
            >
              {value}
            </ListItem>
          </TableCell>
        );
      })}
    </TableRow>
  );
}

Row.propTypes = {
  columns: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
};

export { Row };
