import Keycloak from 'keycloak-js';

import { KEYCLOAK_REALM, KEYCLOAK_CLIENT_ID, KEYCLOAK_URL } from '#/globals';

const options = {
  'ssl-required': 'external',
  resource: KEYCLOAK_CLIENT_ID,
  'public-client': true,
  'confidential-port': 0,
};

const keycloakConf = {
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
  url: KEYCLOAK_URL,
  ...options,
};

const keycloak = new Keycloak(keycloakConf);

export { keycloak };
