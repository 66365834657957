['REACT_APP_GRAPHQL_API_URL'].forEach(name => {
  if (!process.env[name]) {
    throw new Error(`FATAL: ${name} is missing.`);
  }
});

const {
  REACT_APP_TEAM_NAME,

  REACT_APP_GRAPHQL_API_URL,

  REACT_APP_KEYCLOAK_CLIENT_ID,
  REACT_APP_KEYCLOAK_URL,
  REACT_APP_KEYCLOAK_REALM,

  REACT_APP_CSV_API_URL,
} = process.env;

export const TEAM_NAME = REACT_APP_TEAM_NAME;

export const GRAPHQL_API_URL = REACT_APP_GRAPHQL_API_URL;

export const KEYCLOAK_REALM = REACT_APP_KEYCLOAK_REALM;
export const KEYCLOAK_CLIENT_ID = REACT_APP_KEYCLOAK_CLIENT_ID;
export const KEYCLOAK_URL = REACT_APP_KEYCLOAK_URL;

export const CSV_API_URL = REACT_APP_CSV_API_URL;
