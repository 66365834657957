import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  InputAdornment,
  TextField,
  FormControlLabel,
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import DateRangeIcon from '@material-ui/icons/DateRange';

import { DatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';

import { VisiaPaper } from '#/components';

const useStyles = makeStyles(theme => ({
  icon: {
    cursor: 'pointer',
    marginRight: 4,
  },
  dialogContent: {
    display: 'flex',
    padding: '0px !important',
  },
  visiaPaper: {
    margin: 0,
  },
  dialogActions: {
    backgroundColor: theme.palette.grey[100],
  },
  control: {
    width: '100%',
  },
  label: {
    alignItems: 'start',
    marginLeft: 0
  },
}));

const formatDate = d => moment(d).tz('Europe/Paris').format('MMM YYYY');

const FromToPicker = ({
  value,
  onChange,
  views,
  openTo,
  minDate,
  maxDate,
  label,
}) => {
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState(
    value.length
      ? moment(value[0]).tz('Europe/Paris')
      : moment().tz('Europe/Paris')
  );
  const [toDate, setToDate] = useState(
    value.length
      ? moment(value[1]).tz('Europe/Paris')
      : moment().tz('Europe/Paris')
  );
  const classes = useStyles();
  const theme = useTheme();
  const textFieldValue =
    value.length === 0
      ? ''
      : `${formatDate(value[0])} - ${formatDate(value[1])}`;

  const handleClickOpen = ev => {
    setOpen(true);
  };

  const handleClose = submit => () => {
    if (submit) {
      onChange([
        fromDate.startOf('month').format('YYYY-MM-DDT00:00:00') + 'Z',
        toDate.endOf('month').format('YYYY-MM-DDT00:00:00') + "Z",
      ]);
    } else {
      setFromDate(
        value.length
          ? moment(value[0]).tz('Europe/Paris')
          : moment().tz('Europe/Paris')
      );
      setToDate(
        value.length
          ? moment(value[1]).tz('Europe/Paris')
          : moment().tz('Europe/Paris')
      );
    }
    setOpen(false);
  };

  const handleClearClick = ev => {
    ev.stopPropagation();
    ev.preventDefault();
    setFromDate(
      value.length && value[0]
        ? moment(value[0]).tz('Europe/Paris')
        : moment().tz('Europe/Paris')
    );
    setToDate(
      value.length > 1 && value[1]
        ? moment(value[1]).tz('Europe/Paris')
        : moment().tz('Europe/Paris')
    );
    onChange([]);
  };

  return (
    <React.Fragment>
      <FormControlLabel
        label={label}
        labelPlacement="top"
        classes={{ root: classes.control, labelPlacementTop: classes.label }}
        control={
          <TextField
            fullWidth
            placeholder="Date..."
            size="small"
            variant="outlined"
            value={textFieldValue}
            onClick={handleClickOpen}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <DateRangeIcon
                    onClick={handleClickOpen}
                    color="action"
                    className={classes.icon}
                  />
                </InputAdornment>
              ),
              endAdornment: textFieldValue !== '' && (
                <ClearIcon
                  onClick={handleClearClick}
                  color="action"
                  className={classes.icon}
                />
              ),
            }}
          />
        }
      />
      <Dialog maxWidth="md" open={open} onClose={handleClose(false)}>
        <DialogContent className={classes.dialogContent}>
          <VisiaPaper
            elevation={1}
            className={classes.visiaPaper}
            title="Depuis"
            color={theme.palette.panel.green}
            icon={<DateRangeIcon />}
            padding="none"
          >
            <DatePicker
              autoOk
              views={views}
              variant="static"
              openTo={openTo}
              value={fromDate}
              onChange={setFromDate}
              minDate={minDate}
              maxDate={maxDate}
            />
          </VisiaPaper>
          <VisiaPaper
            elevation={1}
            className={classes.visiaPaper}
            title="Jusqu'à"
            color={theme.palette.panel.pink}
            icon={<DateRangeIcon />}
            padding="none"
          >
            <DatePicker
              minDate={fromDate}
              autoOk
              views={views}
              variant="static"
              openTo={openTo}
              value={toDate}
              onChange={setToDate}
              maxDate={maxDate}
            />
          </VisiaPaper>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleClose(true)}
            disabled={!toDate && !fromDate}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

FromToPicker.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  views: PropTypes.array.isRequired,
  openTo: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  label: PropTypes.string,
};

FromToPicker.defaultProps = {
  views: ['year', 'month'],
  openTo: 'month',
};

export { FromToPicker };
